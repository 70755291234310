import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const FooterContainer = styled.div`
  background-color: #101522;
  display: flex;
  justify-content: center;
  padding: 40px 0 40px 0;
  border-top: 3px solid #0cb1ed;
`;

export const FooterWrapper2 = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ToolContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 170px;
  justify-content: center;
  margin: 30px 20px 20px 20px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  align-items: center;
`;

export const BrandTitle = styled.h5`
  color: white;

  font-weight: bold;
  font-size: 20px;
  padding-left: 0.7rem;
`;

export const BrandSubtitle = styled.p`
  color: white;
  font-size: 8px;
  font-weight: 50px;
  padding-left: 13px;
`;

export const FooterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  flex-direction: row;
`;

export const FooterColumn = styled.div`
  margin-bottom: 10px;
  padding-right: 20px;
  padding-left: 50px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  @media screen and (max-width: 768px) {
    justify-content: center;
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  // width: 300px;
  margin: 0 20px 0 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper2 = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
`;

export const FooterText = styled.p`
  color: white;
  font-size: 10px;
  padding-top: 10px;
  padding-bottom: 8px;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LinkS = styled.a`
  padding: 0 20px 0 0;
  color: white;
  font-size: 10px;
  text-decoration: none;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 20px 0 20px 0;
`;

export const FooterLink = styled(Link)`
  font-size: 10px;
  color: white;
  font-size: 12px;
  padding: 0 0 0 10px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: end;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
`;
