import "./App.css";
import GlobalStyle from "./GlobalStyles";
import Navbar from "./components/Navbar";
import Team from "./components/Team/Team"
import Checker from "./components/Checker/Checker";
import Footer from "./components/Footer/Footer";
import AboutUs from "./components/About-Us/About-Us";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <GlobalStyle />
        <Navbar />
        <Routes>
          <Route path="/" element={<Checker />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path='/contact-us' element={<Team />}/>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
