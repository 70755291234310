import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { styled } from "@mui/material/styles";

import { TextField } from "@mui/material";
import "./Checker.css";
import Loading from "../Loading";
import axios from "axios";
import ResultBar from "../ResultBar";

import {
  CheckerSection,
  CheckerContainer,
  CheckerWrapper,
  CheckerOptions,
  CheckerButton,
  BtnWrapper,
  Button,
  ResultSection,
  ResultContainer,
  Heading,
  // CardWrapper,
  // FileUploadCard,
  // FileCardDetail,
  Wrapper,
  UserInputButton,
  // PopUpContainer,
  // PopUpContent,
  // Card,
  // CardDetail,
  // Title,
  // Subtitle,
} from "./Checker.elements";

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    opacity: 0.8,
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
  },
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
});

const Checker = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [input, setInput] = useState("");
  const [userInput, setUserInput] = useState("");
  const [prediksi, setPrediksi] = useState("");
  const [persentase, setPersentase] = useState("");
  const [persentase2, setPersentase2] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleVerifyJudul = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const data = JSON.stringify({
        judul: input,
        narasi: "fdsjljklfsdklj",
      });

      await axios
        .post("https://backend.clarifact.ai/predict_title", data, {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          setPrediksi(res.data.prediction);

          let persen = Math.round((100 - res.data.percentage) * 100) / 100;
          let sisaPersen = Math.round(res.data.percentage * 100) / 100;

          setUserInput(input);
          setPersentase(persen);
          setPersentase2(sisaPersen);
          setLoading(false);
          setInput("");
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleVerifyNarasi = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const data = JSON.stringify({
        judul: "test",
        narasi: input,
      });

      await axios
        .post("https://backend.clarifact.ai/predict_narasi", data, {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          console.log(input);
          setPrediksi(res.data.prediction);

          let persen = Math.round((100 - res.data.percentage) * 100) / 100;
          let sisaPersen = Math.round(res.data.percentage * 100) / 100;

          setUserInput(input);
          setPersentase(persen);
          setPersentase2(sisaPersen);
          setLoading(false);
          setInput("");
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitButton = () => {
    var popup = document.getElementById("popup");
    setPopup(true);
    popup.style.display = "block";
  };

  const handleCloseButton = () => {
    var popup = document.getElementById("popup");
    setPopup(false);
    popup.style.display = "none";
  };

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        var popup = document.getElementById("popup");
        setPopup(false);
        popup.style.display = "none";
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useLayoutEffect(() => {
    if (popup) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!popup) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [popup]);

  return (
    <>
      <CheckerSection>
        <CheckerWrapper>
          <CheckerContainer>
            <CheckerOptions>
              <CheckerButton
                active={selectedIndex === 0 ?? true}
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0)}
                sx={{ ...(selectedIndex === 0) }}
              >
                Judul
              </CheckerButton>
              <CheckerButton
                active={selectedIndex === 1 ?? true}
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}
                sx={{ ...(selectedIndex === 1) }}
              >
                Narasi
              </CheckerButton>
            </CheckerOptions>
            {selectedIndex === 0 && (
              <CustomTextField
                fullWidth
                multiline
                placeholder="Masukkan judul yang ingin diperiksa"
                rows={8}
                value={input}
                onChange={handleChange}
                minLength={5}
              />
            )}
            {selectedIndex === 1 && (
              <CustomTextField
                fullWidth
                multiline
                placeholder="Masukkan narasi yang ingin diperiksa"
                rows={8}
                value={input}
                onChange={handleChange}
              />
            )}
          </CheckerContainer>
          {(() => {
            if (input) {
              if (selectedIndex === 0) {
                return (
                  <BtnWrapper>
                    {loading ? (
                      <Loading />
                    ) : (
                      <Button onClick={handleVerifyJudul}>Verifikasi</Button>
                    )}
                  </BtnWrapper>
                );
              }
              if (selectedIndex === 1) {
                return (
                  <BtnWrapper>
                    {loading ? (
                      <Loading />
                    ) : (
                      <Button onClick={handleVerifyNarasi}>Verifikasi</Button>
                    )}
                  </BtnWrapper>
                );
              }
            } else {
              return (
                <BtnWrapper>
                  <Button disabled={true}>Verifikasi</Button>
                </BtnWrapper>
              );
            }
          })()}
          {/* result */}
          {loading ? null : (
            <>
              <ResultSection>
                <ResultContainer>
                  <Wrapper>
                    {(() => {
                      if (
                        prediksi.length > 0 &&
                        userInput.trim().split(/\s+/).length <= 8
                      ) {
                        return (
                          <div className="result-input">
                            <h1 style={{ marginBottom: "10px" }}>
                              Input Anda:
                            </h1>
                            <p>{userInput}</p>
                          </div>
                        );
                      }
                    })()}
                    {(() => {
                      if (
                        prediksi.length > 0 &&
                        userInput.trim().split(/\s+/).length > 8
                      ) {
                        return (
                          <div className="result-input">
                            <h1 style={{ marginBottom: "10px" }}>
                              Input Anda:
                            </h1>
                            <p>
                              {userInput.split(/\s+/).slice(0, 8).join(" ")} ...
                            </p>
                            <BtnWrapper userinput>
                              <UserInputButton
                                onClick={() => handleSubmitButton()}
                                id="popup-button"
                              >
                                Selengkapnya
                              </UserInputButton>
                            </BtnWrapper>

                            <div
                              className={`overlay ${popup ? "visible" : ""}`}
                            />
                            <div
                              ref={ref}
                              className="popup-container"
                              id="popup"
                            >
                              <div className="popup-content">
                                <div className="popup-header">
                                  <h1 style={{ fontSize: "20px" }}>
                                    Input Anda:
                                  </h1>
                                  <span
                                    onClick={() => handleCloseButton()}
                                    className="close-button"
                                  >
                                    &times;
                                  </span>
                                </div>
                                <div className="popup-body">
                                  <p
                                    style={{
                                      fontSize: "15px",
                                      textAlign: "justify",
                                    }}
                                  >
                                    {userInput}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })()}

                    {(() => {
                      if (prediksi) {
                        if (prediksi === "Hoax") {
                          return (
                            <ResultBar
                              backgroundColor="lightgrey"
                              visualParts={[
                                {
                                  name: "hoax",
                                  value: persentase2,
                                  color: "#fc6d62",
                                },
                                {
                                  name: "fakta",
                                  value: persentase,
                                  color: "#93eb60",
                                },
                              ]}
                            />
                          );
                        }
                        if (prediksi === "Fakta") {
                          return (
                            <ResultBar
                              backgroundColor="lightgrey"
                              visualParts={[
                                {
                                  name: "hoax",
                                  value: persentase2,
                                  color: "#fc6d62",
                                },
                                {
                                  name: "fakta",
                                  value: persentase,
                                  color: "#93eb60",
                                },
                              ]}
                            />
                          );
                        }
                      }
                    })()}

                    {prediksi.length > 0 && (
                      <Heading>
                        Input kemungkinan besar adalah {prediksi}!
                      </Heading>
                    )}
                  </Wrapper>
                </ResultContainer>
              </ResultSection>
            </>
          )}
        </CheckerWrapper>
      </CheckerSection>
    </>
  );
};

export default Checker;
