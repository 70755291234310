import "./Team.css";
import team1 from "../../images/pngegg.png";
import karen from "../../images/karen.png";
import monique from "../../images/monique.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import Email from "@mui/icons-material/Email";
import edo from "../../images/edo.png";
import bagus from "../../images/bagus.png";
import mc from "../../images/mc.png";
import nurul from "../../images/nurul.png";
import karin from "../../images/karin.png";
import van from "../../images/van.png";

export default function Team() {
  return (
    <div className="App">
      <div id="model3">
        <h1 className="model-title">Yuk kenalan dengan tim ClariFact-AI! </h1>
        <div className="divider"></div>
        <div className="members">
          <div className="member">
            <img width={200} height={200} src={karen} />
            <div className="description">
              <h1>Angeline Karen</h1>
              <h2>
                Model Training <br /> Frontend Developer
              </h2>
              <p>
                <a href="mailto:angeline.karen.ac.id">
                  angeline.karen@binus.ac.id
                </a>
              </p>
              <div className="social-media">
                <a href="https://www.instagram.com/angelinekarenn">
                  <InstagramIcon />
                </a>
                <a href="https://www.linkedin.com/in/angeline-karen-2409a4240/">
                  <LinkedInIcon />
                </a>
                <a href="mailto:angeline.karen@binus.ac.id">
                  <EmailIcon />
                </a>
              </div>
            </div>
          </div>

          <div className="member">
            <img width={200} height={200} src={edo} />
            <div className="description">
              <h1>Edward Kurniawan</h1>
              <h2>
                Data Preprocessing
                <br /> Backend Developer
              </h2>
              <p>
                <a href="mailto:edward.kurniawan@binus.ac.id">
                  edward.kurniawan@binus.ac.id
                </a>
              </p>
              <div className="social-media">
                <a href="https://www.instagram.com/thecipherrr">
                  <InstagramIcon />
                </a>
                <a href="https://www.linkedin.com/in/edward-matthew-348211221">
                  <LinkedInIcon />
                </a>
                <a href="mailto:edward.kurniawan@binus.ac.id">
                  <EmailIcon />
                </a>
              </div>
            </div>
          </div>

          <div className="member">
            <img width={200} height={200} src={mc} />
            <div className="description">
              <h1>Michael Christopher</h1>
              <h2>
                Data Preprocessing
                <br /> Backend Developer
              </h2>
              <p>
                <a href="mailto:michael.christopher001@binus.ac.id">
                  michael.christopher001@binus.ac.id
                </a>
              </p>
              <div className="social-media">
                <a href="https://www.instagram.com/michael_christopher0">
                  <InstagramIcon />
                </a>
                <a href="https://www.linkedin.com/in/michael-christopher-9910b3258">
                  <LinkedInIcon />
                </a>
                <a href="mailto:michael.christopher001@binus.ac.id">
                  <EmailIcon />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="members">
          <div className="member">
            <img width={200} height={200} src={monique} />
            <div className="description">
              <h1>Monique Senjaya</h1>
              <h2>
                Data Preprocessing <br /> Frontend Developer
              </h2>
              <p>
                <a href="mailto:monique.senjaya@binus.ac.id">
                  monique.senjaya@binus.ac.id
                </a>
              </p>
              <div className="social-media">
                <a href="https://www.instagram.com/moniquesenjaya">
                  <InstagramIcon />
                </a>
                <a href="https://www.linkedin.com/in/monique-senjaya-06a33115a/">
                  <LinkedInIcon />
                </a>
                <a href="mailto:monique.senjaya@binus.ac.id">
                  <EmailIcon />
                </a>
              </div>
            </div>
          </div>
          <div className="member">
            <img width={200} height={200} src={van} />
            <div className="description">
              <h1>Vania Natalie</h1>
              <h2>
                Model Training
                <br /> Frontend Developer
              </h2>
              <p>
                <a href="mailto:vania.aherman@binus.ac.id">
                  vania.aherman@binus.ac.id
                </a>
              </p>
              <div className="social-media">
                <a href="https://www.instagram.com/vaniaanatalie">
                  <InstagramIcon />
                </a>
                <a href="https://www.linkedin.com/in/vania-natalie-54a7a020a">
                  <LinkedInIcon />
                </a>
                <a href="mailto:vania.aherman@binus.ac.id">
                  <EmailIcon />
                </a>
              </div>
            </div>
          </div>
          <div className="member">
            <img width={200} height={200} src={bagus} />
            <div className="description">
              <h1>Ida Bagus Kerthyayana Manuaba</h1>
              <h2>Supervisor</h2>
              <p>
                <a href="mailto:imanuaba@binus.edu">imanuaba@binus.edu</a>
              </p>
              <div className="social-media">
                <a href="https://www.instagram.com/kerthyayanaz/">
                  <InstagramIcon />
                </a>
                <a href="https://www.linkedin.com/in/ida-bagus-kerthyayana-manuaba/">
                  <LinkedInIcon />
                </a>
                <a href="mailto:imanuaba@binus.edu">
                  <EmailIcon />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="members">
          <div className="member">
            <img width={200} height={200} src={nurul} />
            <div className="description">
              <h1>Nunung Nurul Qomariyah</h1>
              <h2>Supervisor</h2>
              <p>
                <a href="mailto:nunung.qomariyah@binus.edu">
                  nunung.qomariyah@binus.edu
                </a>
              </p>
              <div className="social-media">
                <a href="https://www.instagram.com/nnqomariyah/">
                  <InstagramIcon />
                </a>
                <a href="https://www.linkedin.com/in/nunungqomariyah/">
                  <LinkedInIcon />
                </a>
                <a href="mailto:nunung.qomariyah@binus.edu">
                  <EmailIcon />
                </a>
              </div>
            </div>
          </div>
          <div className="member">
            <img width={200} height={200} src={karin} />
            <div className="description">
              <h1>Andari Karina Anom</h1>
              <h2>Supervisor</h2>
              <p>
                <a href="mailto:aanom@binus.edu">aanom@binus.edu</a>
              </p>
              <div className="social-media">
                <a href="https://www.instagram.com/andarikarina/">
                  <InstagramIcon />
                </a>
                <a href="https://www.linkedin.com/in/andarika/">
                  <LinkedInIcon />
                </a>
                <a href="mailto:aanom@binus.edu">
                  <EmailIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
