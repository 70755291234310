import styled, { css } from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  padding: 50px 0 0 0;
`;

export const Title = styled.p`
  transform: translate(0%, -50%);
  color: white;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  margin-top: -80px;
  padding: 0 20px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }

  @media screen and (max-width: 614px) {
    font-size: 40px;
  }
`;

export const Subtitle = styled.p`
  transform: translate(0%, -50%);
  color: white;
  text-align: center;
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 50;
  margin-top: -20px;
  padding: 0 60px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    margin-top: -50px;
  }

  @media screen and (max-width: 614px) {
    font-size: 18px;
    margin-top: -40px;
  }
`;

export const ContainerText = styled.div`
  background: white;
`;

export const Container1 = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
`;

export const ContainerBox = styled.div`
  margin: 0px auto;
  position: relative;
`;

export const Box = styled.div`
  background: white;
  margin: auto;
  border-radius: 0.8rem;
  width: 90%;
  z-index: 2;
  top: -150px;
  padding: 50px;
  position: relative;
`;

export const ShadowBox = styled.div`
  border-radius: 0.8rem;
  margin: auto;
  width: 95%;
  height: 100%;
  background: #005c8b;
  top: -7.5rem;
  margin-left: 2.5%;
  position: absolute;
  z-index: 1;
`;

export const Heading = styled.h1`
  font-size: 45px;
  margin-top: 10px;
  margin-bottom: 25px;
  margin-left: 2%;
`;

export const Subtext = styled.span`
  color: #005c8b;
`;

export const Text = styled.p`
  text-align: justify;
  font-size: 20px;
  line-height: 25px;
  margin-right: 10%;
  margin-left: 2%;
  ${(props) =>
    props.box &&
    css`
      margin-right: 0%;
      margin-top: 25px;
    `}
`;

export const Container2 = styled.div`
  padding: 0 20px;
`;

export const Container3 = styled.div`
  padding: 100px 20px;
`;
