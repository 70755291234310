import childrenpic from "../../images/children-pic.jpg";

import "./about-us.css";
import {
  Container2,
  Container3,
  TextWrapper,
  Title,
  Container,
  Subtitle,
  Subtext,
  Heading,
  ContainerText,
  Text,
  Box,
  ShadowBox,
  ContainerBox,
  Container1,
} from "./About-Us-Elements";

const AboutUs = () => {
  return (
    <div>
      <Container>
        <img
          className="children"
          src={childrenpic}
          alt="Children Indonesia Logo"
          width="100%"
          height="100%"
        />
        <TextWrapper>
          <Title>
            Mendeteksi Berita Hoax Untuk Membangun Masyarakat Indonesia yang
            Lebih Cerdas
          </Title>
          <Subtitle>
            ClariFact-AI bertujuan untuk mengurangi masyarakat Indonesia yang
            terpapar berita hoaks untuk membangun masyarakat Indonesia yang
            lebih <b>bijak</b> dan <b>cerdas</b> dalam mengonsumsi informasi
          </Subtitle>
        </TextWrapper>
      </Container>

      <ContainerText>
        <Container1>
          <ContainerBox>
            <Box>
              <h1>
                Apa itu <Subtext>ClariFact-AI</Subtext>?
              </h1>
              <Text box>
                ClariFact-AI adalah sebuah program yang dibuat oleh jurusan
                Teknik Informatika di Universitas Bina Nusantara Internasional
                untuk mengecek apakah sebuah informasi merupakan fakta atau
                hoaks. Menurut survei yang diadakan oleh Katadata Insight Center
                di 2020, ada kurang lebih 60% orang Indonesia terpapar hoaks
                saat mengakses dunia maya, dan hanya 21% hingga 36% yang mampu
                membedakan dan mengenali bahwa informasi tersebut merupakan
                hoaks. Dengan demikian, proyek ini diajukan dengan harapan dapat
                mengurangi jumlah masyarakat yang terpapar hoaks dan dapat
                membantu mengidentifikasi berita hoaks yang tersebar di
                internet.
              </Text>
            </Box>
            <ShadowBox></ShadowBox>
          </ContainerBox>
        </Container1>

        <Container2>
          <Heading>
            Cara Menggunakan <Subtext>ClariFact-AI</Subtext>
          </Heading>
          <Text>
            Ketika Anda sedang menjelajah internet, Anda mungkin menemukan
            sebuah artikel yang membuat Anda tertarik. Namun, Anda dapat
            meragukan kebenaran dari artikel tersebut. Disinilah ClariFact-AI
            dapat membantu Anda. Kami dapat membantu Anda mengidentifikasi dan
            mengukur apakah informasi yang Anda baca merupakan kebenaran atau
            hoaks. Ketika membuka situs halaman ClariFact-AI, Anda dapat melihat
            kotak untuk memasukkan teks. Anda dapat memilih untuk memasukkan
            judul ataupun narasi (beberapa kalimat) dengan memilih salah satu
            dari tombol di atas kotak teks tersebut bertuliskan "judul" atau
            "narasi". Anda dapat menyalin judul atau narasi artikel sesuai
            dengan yang Anda inginkan dan menempelkan pada kotak teks tersebut
            kemudian klik tombol “verifikasi” yang terletak di bawah kotak teks.
            Hasil dari analisa ClariFact-AI pun akan muncul.
          </Text>
        </Container2>

        <Container3>
          <Heading>
            Algoritma <Subtext>ClariFact-AI</Subtext>
          </Heading>
          <Text>
            ClariFact-AI menggunakan biLSTM model (Bidirectional LSTM) yang
            digunakan dalam deep learning untuk menganalisa dan memprediksi
            apakah sebuah teks dapat dikategorikan sebagai hoaks. Model tersebut
            digunakan untuk memprediksi teks judul dan narasi. Model yang
            digunakan untuk judul dilatih dengan lebih dari 12,000 data dan
            memiliki akurasi lebih dari 80%. Model yang digunakan untuk narasi
            dilatih dengan lebih dari 8,000 data dan memiliki akurasi lebih dari
            90%.
          </Text>
        </Container3>

        {/* <Heading>Apa itu <Subtext>ClariFact-AI?</Subtext></Heading>
                <Text>ClariFact-AI adalah sebuah program yang dibuat oleh jurusan Teknik Informatika di Universitas Bina Nusantara 
                    Internasional untuk mengecek apakah sebuah informasi merupakan fakta atau hoaks. Menurut survei yang 
                    diadakan oleh Katadata Insight Center di 2020, ada kurang lebih 60% orang Indonesia terpapar hoaks saat 
                    mengakses dunia maya, dan hanya 21% hingga 36% yang mampu membedakan dan mengenali bahwa informasi tersebut 
                    merupakan hoaks. Dengan demikian, proyek ini diajukan dengan harapan dapat mengurangi jumlah masyarakat yang 
                    terpapar hoaks dan dapat membantu mengidentifikasi berita hoaks yang tersebar di internet.</Text> */}
      </ContainerText>
    </div>
  );
};
export default AboutUs;
