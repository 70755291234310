import styled, {css} from "styled-components";

export const CheckerSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0 30px 0;
  min-height: 100vh;
`;

export const CheckerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 1000px;
`;

export const CheckerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin: 0 20px 0 24px;
`;

export const CheckerOptions = styled.div`
  display: flex;
  margin: 0 0 15px 0;
`;

export const CheckerButton = styled.button`
  position: relative;
  display: inline-block;
  margin: 1em;
  padding: 0.5em 2em;
  border: 0;
  border-radius: 24px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: ${({ active }) => (active ? "#42a5f5" : "transparent")};
  border: 1px solid #42a5f5;
  color: ${({ active }) => (active ? "white" : "#42a5f5")};

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05), 0 0px 20px rgba(0, 0, 0, 0.08);
  }

  // .active {
  //   transform: translateY(1px);
  //   background-color: #2bc66a;
  //   color: white;
  // }

  &:focus {
    outline: none;
  }
`;

export const Button = styled.button`
  border-radius: 22px;
  background: #42a5f5;
  padding: 10px 80px;
  color: white;
  font-weight: bold;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  &:hover {
    background: #1976d2;
  }
  &:disabled {
    background: #b1cade;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  ${props => props.userinput && css`
  padding-top: 15px;
  margin-bottom: 50px;
`}
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FileUploadCard = styled.div`
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  // width: 500px;

  height: 200px;
  justify-content: center;
  border-radius: 20px;
`;

export const FileCardDetail = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;

  font-weight: 600;
  color: #000;
`;

export const Wrapper = styled.div`
  padding-top: 0;
  padding-bottom: 60px;
  @media screen and (max-width: 768px) {
    padding-bottom: 10px;
  }
`;

export const Card = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 3px dashed #2bc66a;
  // background-color: #edf2f7;
  min-width: 350px;
  min-height: 100px;
`;

export const CardDetail = styled.div`
  position: relative;
  margin-bottom: 1.5em;
`;

export const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
`;

export const Subtitle = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  margin: 4px 0 10px 0;
  color: #aaaaaa;
`;

export const ResultSection = styled.div`
  background: #fff;
  padding: 120px 0 0 0;
  color: #000;
`;

export const ResultContainer = styled.div`
  max-width: 1300px;
  width: 100%;
  z-index: 1;
  margin-right: auto;
  margin-left: auto;
  // padding-right: 50px;
  // padding-left: 50px;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Heading = styled.h2`
  margin: 22px 0;
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
  color: #000;
`;

export const UserInputButton = styled.button`
  background: #909090;
  padding: 10px 30px;
  color: white;
  font-weight: bold;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  &:hover {
    background: #636363;
  }
`;

export const PopUpContainer = styled.div`
  border-radius: 15px;
  position: fixed;
  top: 30%;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 500ms;
  // visibility: hidden;
  // opacity: 0;
  width: 500px;
  height: 300px;
  z-index: 3;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background: blue;
  
  // @media screen and (max-width: 991px) {
  //   padding-right: 30px;
  //   padding-left: 30px;
  // }
`;

export const PopUpContent = styled.div`
padding: 33px;
border-radius: 5px;
position: relative;
transition: all 5s ease-in-out;
  
  // @media screen and (max-width: 991px) {
  //   padding-right: 30px;
  //   padding-left: 30px;
  // }
`;