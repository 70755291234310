import React from "react";
import logo from "../../images/clarifact_ai_logo.png";
// import turnBackHoaxLogo from "../../images/turnbackhoax_logo.png";
// import apolloLogo from "../../images/apollo_logo.png";
import binusLogo from "../../images/binusinter_logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";

import {
  FooterContainer,
  FooterWrapper,
  FooterRow,
  FooterColumn,
  FooterLogo,
  TitleWrapper,
  BrandSubtitle,
  BrandTitle,
  LogoContainer,
  FooterText,
  LinkContainer,
  FooterLink,
  ToolContainer,
  Container,
  InfoContainer,
  LinkS,
  TitleWrapper2,
} from "./Footer.elements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterRow>
          <FooterColumn>
            <FooterLogo>
              <img src={logo} alt="ClariFact-AI Logo" width="38" height="40" />
              <TitleWrapper>
                <BrandTitle>ClariFact-AI</BrandTitle>
                <BrandSubtitle>
                  Klarifikasi Fakta Berbasis Artificial Intelligence
                </BrandSubtitle>
              </TitleWrapper>
            </FooterLogo>
            <InfoContainer>
              <ToolContainer>
                <TitleWrapper2>
                  <FooterText>Referenced tools:</FooterText>
                  <LogoContainer>
                    <LinkS href="https://turnbackhoax.id/">
                      {/* <img
                        src={turnBackHoaxLogo}
                        alt="turnbackhoax.id Logo"
                        width="30"
                        height="33"
                      /> */}
                      TurnBackHoax.ID
                    </LinkS>
                    <LinkS href="https://projectapollo.ai/">
                      {/* <img
                        src={apolloLogo}
                        alt="projectapollo.ai Logo"
                        width="100"
                        height="30"
                      /> */}
                      Apollo Anti Hoax
                    </LinkS>
                    <LinkS href="https://www.kompas.id/label/kliping-kompas">
                      Kompas
                    </LinkS>
                    <LinkS href="https://www.tempo.co/">Tempo.co</LinkS>
                  </LogoContainer>
                </TitleWrapper2>
              </ToolContainer>
              <ToolContainer>
                <TitleWrapper>
                  <FooterText>Supported by Binus International</FooterText>
                  <LogoContainer>
                    <img
                      src={binusLogo}
                      alt="Binus International Logo"
                      width="70"
                      height="40
                "
                    />
                  </LogoContainer>
                </TitleWrapper>
              </ToolContainer>
            </InfoContainer>
          </FooterColumn>

          <FooterColumn>
            <Container>
              <a href="https://www.instagram.com/clarifact.ai/">
                <InstagramIcon sx={{ color: "white" }} />
              </a>

              <LinkContainer>
                <FooterLink to="/">Checker</FooterLink>

                <FooterLink to="/about-us">About Us</FooterLink>

                <FooterLink to="/contact-us">Contact Us</FooterLink>
              </LinkContainer>
              <FooterText>
                &copy; 2022 ClariFact-AI • All Rights Reserved
              </FooterText>
            </Container>
          </FooterColumn>
        </FooterRow>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
