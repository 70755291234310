import React, { useEffect, useState } from "react";
import "./index.css";

const ResultBar = ({
  props,
  backgroundColor = "#e5e5e5",
  // expected format for visual parts
  visualParts = [
    {
      name: "hoax",
      value: "0",
      color: "white",
    },
  ],
}) => {
  // Starting values needed for the animation
  // Mapped by "visualParts" so it can work with multiple values dynamically
  // It's an array of percentage widths
  const [widths, setWidths] = useState(
    visualParts.map(() => {
      return 0;
    })
  );

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
    // You need to wrap it to trigger the animation
    requestAnimationFrame(() => {
      // Set a new array of percentage widths based on the props
      setWidths(
        visualParts.map((item) => {
          return item.value;
        })
      );
    });
  }, [visualParts]);

  let values =
    visualParts &&
    visualParts.length &&
    visualParts.map(function (item, i) {
      if (item.value > 0) {
        return (
          <div
            className="value"
            style={{ color: item.color, width: item.value + "%" }}
            key={i}
          >
            <span>{item.value}%</span>
          </div>
        );
      }
    });

  let calibrations =
    visualParts &&
    visualParts.length &&
    visualParts.map(function (item, i) {
      if (item.value > 0) {
        return (
          <div
            className="graduation"
            style={{ color: item.color, width: item.value + "%" }}
            key={i}
          >
            <span>|</span>
          </div>
        );
      }
    });

  let bars =
    visualParts &&
    visualParts.length &&
    visualParts.map(function (item, i) {
      if (item.value > 0) {
        return (
          <div
            className="bar"
            style={{ backgroundColor: item.color, width: item.value + "%" }}
            key={i}
          ></div>
        );
      }
    });

  let legends =
    visualParts &&
    visualParts.length &&
    visualParts.map(function (item, i) {
      if (item.value > 0) {
        return (
          <div className="legend" key={i}>
            <span className="dot" style={{ color: item.color }}>
              ●
            </span>
            <span className="label">{item.name}</span>
          </div>
        );
      }
    });

  return (
    <>
      <div className="multicolor-bar">
        <div className="values">{values == "" ? "" : values}</div>
        <div className="scale">{calibrations == "" ? "" : calibrations}</div>
        <div className="bars">{bars == "" ? "" : bars}</div>
        <div className="legends">{legends == "" ? "" : legends}</div>
      </div>
    </>
  );
};

export default ResultBar;
