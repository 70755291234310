import { React, useEffect, useState, useRef, useLayoutEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoIcon from "@mui/icons-material/Info";
import CallIcon from "@mui/icons-material/Call";
import logo from "../../images/clarifact_ai_logo.png";
import "./Navbar.css";

import {
  Nav,
  NavContainer,
  NavLogo,
  NavMenu,
  NavItem,
  NavLink,
  BrandTitle,
  BrandSubtitle,
  TitleWrapper,
  SidebarMenu,
  SidebarItem,
  SidebarLink,
} from "./Navbar.elements";

const Navbar = () => {
  const [navChangeColor, setNavChangeColor] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setNavChangeColor(true);
    } else {
      setNavChangeColor(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 1065) {
      setMobile(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1065) {
        setMobile(true);
      } else {
        setMobile(false);
        setSidebar(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  window.addEventListener("scroll", changeNavbarColor);

  const handleToggleHome = () => {
    scroll.scrollToTop();
    setSidebar(false);
  };

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setSidebar(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useLayoutEffect(() => {
    if (sidebar) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    if (!sidebar) {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [sidebar]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <>
      <div className={`overlay ${sidebar ? "visible" : ""}`} />
      <Nav className={navChangeColor ? "navbar active" : "navbar"}>
        <NavContainer>
          <NavLogo to="/" onClick={handleToggleHome}>
            <img src={logo} alt="ClariFact-AI Logo" width="38" height="40" />
            <TitleWrapper>
              <BrandTitle>ClariFact-AI</BrandTitle>
              <BrandSubtitle>
                Klarifikasi Fakta Berbasis Artificial Intelligence
              </BrandSubtitle>
            </TitleWrapper>
          </NavLogo>
          {!mobile && (
            <>
              <NavMenu>
                <NavItem>
                  <NavLink to="/">Checker</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/about-us">About Us</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/contact-us">Contact Us</NavLink>
                </NavItem>
              </NavMenu>
            </>
          )}
          {mobile && (
            <div className="sidebar-toggle">
              <FaBars
                className="sidebar-logo-toggle"
                onClick={() => setSidebar(!sidebar)}
              />
            </div>
          )}
        </NavContainer>
      </Nav>
      <div ref={ref} className={sidebar ? "sidebar active" : "sidebar"}>
        {mobile && (
          <div className="sidebar-toggle">
            <FaTimes
              className="sidebar-logo-toggle"
              onClick={() => setSidebar(!sidebar)}
            />
          </div>
        )}
        <SidebarMenu>
          <SidebarItem
            active={selectedIndex === 0 ?? true}
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
            sx={{ ...(selectedIndex === 0) }}
          >
            <SidebarLink onClick={() => setSidebar(false)} to="/">
              <CheckCircleOutlineIcon
                style={{
                  color: selectedIndex === 0 ? "white" : "black",
                  marginRight: "0.7rem",
                }}
              />
              Checker
            </SidebarLink>
          </SidebarItem>
          <SidebarItem
            active={selectedIndex === 1 ?? true}
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
            sx={{ ...(selectedIndex === 1) }}
          >
            <SidebarLink onClick={() => setSidebar(false)} to="/about-us">
              <InfoIcon
                style={{
                  color: selectedIndex === 1 ? "white" : "black",
                  marginRight: "0.7rem",
                }}
              />
              About Us
            </SidebarLink>
          </SidebarItem>
          <SidebarItem
            active={selectedIndex === 2 ?? true}
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
            sx={{ ...(selectedIndex === 2) }}
          >
            <SidebarLink onClick={() => setSidebar(false)} to="/contact-us">
              <CallIcon
                style={{
                  color: selectedIndex === 2 ? "white" : "black",
                  marginRight: "0.7rem",
                }}
                c
              />
              Contact Us
            </SidebarLink>
          </SidebarItem>
        </SidebarMenu>
      </div>
    </>
  );
};

export default Navbar;
